import type {ContainerQueryResult} from '@Hooks/useContainerQuery';
import type {Ref} from 'react';
import {SocialPlatform} from '@Components/social-media/social-media.types';
import type {PosterHashedId} from '@Components/social-media/publish-params.types';
import type {PlatformMediaType} from '@Components/social-media/post.vo';

export enum SocialMediaWizardStep {
  SELECT_DESIGN = 'SELECT_DESIGN',
  CONNECT_ACCOUNTS = 'CONNECT_ACCOUNTS',
  ACCOUNT_CONNECTION = 'ACCOUNT_CONNECTION',
  SELECT_ACCOUNTS = 'SELECT_ACCOUNTS',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  ADD_DETAILS = 'ADD_DETAILS',
  PUBLISH = 'PUBLISH',
}

export enum SocialMediaWizardMediaTypeSelection {
  POST = 'POST',
  STORY = 'STORY',
  REEL = 'REEL',
  POST_AND_STORY = 'POST_AND_STORY',
  REEL_AND_STORY = 'REEL_AND_STORY',
}

export enum SocialMediaWizardMediaTypeRadioButtonIds {
  POST = 'POST',
  STORY = 'STORY',
  BOTH = 'BOTH',
}

export enum SocialMediaWizardContentSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
}

export interface ErrorResponseUpdatePublishingParams {
  error: {
    message: string;
    code: ErrorUpdatePublishingParams;
  };
}

export enum ErrorUpdatePublishingParams {
  GENERAL_ERROR = 'general-error',
  RATE_LIMITED = 'rate-limited',
  ERROR_SAVING = 'error-saving',
  ALREADY_PUBLISHED = 'already-published',
}

export interface DesignMetaUpdateInfo {
  hasAnyVideo: boolean;
  areAllAccountsSupported: boolean;
}

export interface WizardFlowAdditionalData {
  templateId: undefined | string;
  isGalleryFlow: boolean;
  emphasizedPlatform?: SocialPlatform;
}

export enum CaptionErrorTypes {
  COUNT_ERROR = 'COUNT_ERROR',
  HASHTAG_ERROR = 'HASHTAG_ERROR',
  NO_ERROR = 'NO_ERROR',
}

export enum WizardMobileNavView {
  CONTENT = 'CONTENT',
  PREVIEW = 'PREVIEW',
}

export interface SocialMediaWizardContainerQuery {
  isExtraSmallScreen?: boolean;
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;
  isLargeScreen?: boolean;
  isExtraLargeScreen?: boolean;
}

export const getWizardContentSize = (params: ContainerQueryResult): SocialMediaWizardContentSize => {
  if (params.xsmall) {
    return SocialMediaWizardContentSize.XSMALL;
  }

  if (params.small) {
    return SocialMediaWizardContentSize.SMALL;
  }

  if (params.medium) {
    return SocialMediaWizardContentSize.MEDIUM;
  }

  if (params.large) {
    return SocialMediaWizardContentSize.LARGE;
  }

  return SocialMediaWizardContentSize.XLARGE;
};

export interface ContainerQueryParams {
  containerQueryParamProps: SocialMediaWizardContainerQuery;
  containerRef: Ref<HTMLDivElement>;
  hasContainerQueryInitialized: boolean;
}

export enum AddAccountEvent {
  ADD_ACCOUNT = 'add_account:clicked',
}

export enum ContinueEditingEvent {
  CONTINUE_EDITING = 'continue_editing:clicked',
}

export const captionCharacterLimits: Record<SocialPlatform, number> = {
  [SocialPlatform.FACEBOOK]: 63206,
  [SocialPlatform.FACEBOOK_PROFILE]: 63206,
  [SocialPlatform.FACEBOOK_PAGE]: 63206,
  [SocialPlatform.FACEBOOK_GROUP]: 63206,
  [SocialPlatform.TIKTOK]: 4000,
  [SocialPlatform.YOUTUBE]: 5000,
  [SocialPlatform.LINKEDIN]: 3000,
  [SocialPlatform.LINKEDIN_PAGE]: 3000,
  [SocialPlatform.LINKEDIN_PROFILE]: 3000,
  [SocialPlatform.INSTAGRAM]: 2200,
  [SocialPlatform.PINTEREST]: 600,
  [SocialPlatform.TWITTER]: 280,
  [SocialPlatform.GOOGLE_BUSINESS_PROFILE]: 1500,
  [SocialPlatform.THREADS]: 500,
};

export const titleValidations: Record<SocialPlatform, {areURLsAllowed: boolean; limit: number | null}> = {
  [SocialPlatform.FACEBOOK]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.FACEBOOK_PROFILE]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.FACEBOOK_PAGE]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.FACEBOOK_GROUP]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.TIKTOK]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.YOUTUBE]: {areURLsAllowed: false, limit: 100},
  [SocialPlatform.LINKEDIN]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.LINKEDIN_PAGE]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.LINKEDIN_PROFILE]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.INSTAGRAM]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.PINTEREST]: {areURLsAllowed: true, limit: 100},
  [SocialPlatform.TWITTER]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.GOOGLE_BUSINESS_PROFILE]: {areURLsAllowed: true, limit: null},
  [SocialPlatform.THREADS]: {areURLsAllowed: true, limit: null},
};

export const socialAccountPostCoverValidations: Record<SocialPlatform, {doesSupportCover: boolean; isUploadAllowed: boolean; doesSupportPNGImages: boolean, previewAspectRatio: number}> = {
  [SocialPlatform.FACEBOOK]: {doesSupportCover: false, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 1},
  [SocialPlatform.FACEBOOK_PROFILE]: {doesSupportCover: false, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 1},
  [SocialPlatform.FACEBOOK_PAGE]: {doesSupportCover: true, isUploadAllowed: true, doesSupportPNGImages: true, previewAspectRatio: 1},
  [SocialPlatform.FACEBOOK_GROUP]: {doesSupportCover: false, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 1},
  [SocialPlatform.TIKTOK]: {doesSupportCover: true, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 1},
  [SocialPlatform.YOUTUBE]: {doesSupportCover: false, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 9 / 16},
  [SocialPlatform.LINKEDIN]: {doesSupportCover: true, isUploadAllowed: true, doesSupportPNGImages: true, previewAspectRatio: 1},
  [SocialPlatform.LINKEDIN_PAGE]: {doesSupportCover: true, isUploadAllowed: true, doesSupportPNGImages: true, previewAspectRatio: 1},
  [SocialPlatform.LINKEDIN_PROFILE]: {doesSupportCover: true, isUploadAllowed: true, doesSupportPNGImages: true, previewAspectRatio: 1},
  [SocialPlatform.INSTAGRAM]: {doesSupportCover: true, isUploadAllowed: true, doesSupportPNGImages: false, previewAspectRatio: 9 / 16},
  [SocialPlatform.PINTEREST]: {doesSupportCover: true, isUploadAllowed: true, doesSupportPNGImages: true, previewAspectRatio: 1},
  [SocialPlatform.TWITTER]: {doesSupportCover: false, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 1},
  [SocialPlatform.GOOGLE_BUSINESS_PROFILE]: {doesSupportCover: false, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 1},
  [SocialPlatform.THREADS]: {doesSupportCover: false, isUploadAllowed: false, doesSupportPNGImages: false, previewAspectRatio: 1},
};

export const YoutubeTitleOnScreenMax = 40;

export function countHashtags(inputString: string): number {
  const hashtagRegex = /#(\w+)/g;
  const matches = inputString.match(hashtagRegex);

  return matches ? matches.length : 0;
}

export const BLURABLE_CLASS_IN_AI_CAPTION_MODE = 'js-blurable-in-ai-mode';
export const PUBLISH_PARAM_HANDLER_CLASS = 'js-social-post-publish-param';

export interface DesignsGraphicMetaPayload {
  designId: PosterHashedId;
  isVideo: boolean;
  previewURL: string;
  name: string;
}

export type DesignsGraphicMetaUpdatePayload = {
  idSocialMediaAccount: number;
  designId: PosterHashedId;
  previewURL: string;
  isVideo: boolean;
  mediaType: PlatformMediaType;
};

export type PinterestBoard = {
  description: string;
  id: string;
  name: string;
  owner: {
    username: string;
  };
  privacy: 'PUBLIC' | 'PRIVATE'; // assuming privacy can be 'PUBLIC' or 'PRIVATE'
  media: {
    imageCoverUrl: string;
  };
};

export interface UserHasSeenResponse {
  hasSeen: number;
}

export interface HideSMRemoveAccountConfirmationResponse {
  hideConfirmation: boolean;
}